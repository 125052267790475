// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IExternalLinkFragment } from '../../fragments/__generated__/externalLink.types';
import { gql } from '@apollo/client';
import { ExternalLinkFragmentDoc } from '../../fragments/__generated__/externalLink.types';
import * as Apollo from '@apollo/client';
export type IInsertExternalLinkMutationVariables = Types.Exact<{
  obituary_id: Types.Scalars['uuid'];
  type?: Types.Maybe<Types.IExternal_Link_Type_Enum>;
  uri?: Types.Maybe<Types.Scalars['String']>;
}>;


export type IInsertExternalLinkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_external_link_one?: Types.Maybe<(
    { __typename?: 'external_link' }
    & IExternalLinkFragment
  )> }
);


export const InsertExternalLinkDocument = gql`
    mutation InsertExternalLink($obituary_id: uuid!, $type: external_link_type_enum, $uri: String) {
  insert_external_link_one(
    object: {obituary_id: $obituary_id, type: $type, uri: $uri}
  ) {
    ...ExternalLink
  }
}
    ${ExternalLinkFragmentDoc}`;
export type IInsertExternalLinkMutationFn = Apollo.MutationFunction<IInsertExternalLinkMutation, IInsertExternalLinkMutationVariables>;

/**
 * __useInsertExternalLinkMutation__
 *
 * To run a mutation, you first call `useInsertExternalLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExternalLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExternalLinkMutation, { data, loading, error }] = useInsertExternalLinkMutation({
 *   variables: {
 *      obituary_id: // value for 'obituary_id'
 *      type: // value for 'type'
 *      uri: // value for 'uri'
 *   },
 * });
 */
export function useInsertExternalLinkMutation(baseOptions?: Apollo.MutationHookOptions<IInsertExternalLinkMutation, IInsertExternalLinkMutationVariables>) {
        return Apollo.useMutation<IInsertExternalLinkMutation, IInsertExternalLinkMutationVariables>(InsertExternalLinkDocument, baseOptions);
      }
export type InsertExternalLinkMutationHookResult = ReturnType<typeof useInsertExternalLinkMutation>;
export type InsertExternalLinkMutationResult = Apollo.MutationResult<IInsertExternalLinkMutation>;
export type InsertExternalLinkMutationOptions = Apollo.BaseMutationOptions<IInsertExternalLinkMutation, IInsertExternalLinkMutationVariables>;