// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IExternalLinkFragment } from '../../fragments/__generated__/externalLink.types';
import { gql } from '@apollo/client';
import { ExternalLinkFragmentDoc } from '../../fragments/__generated__/externalLink.types';
import * as Apollo from '@apollo/client';
export type IUpdateExternalLinkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  uri?: Types.Maybe<Types.Scalars['String']>;
}>;


export type IUpdateExternalLinkMutation = (
  { __typename?: 'mutation_root' }
  & { update_external_link_by_pk?: Types.Maybe<(
    { __typename?: 'external_link' }
    & IExternalLinkFragment
  )> }
);


export const UpdateExternalLinkDocument = gql`
    mutation UpdateExternalLink($id: uuid!, $uri: String) {
  update_external_link_by_pk(pk_columns: {id: $id}, _set: {uri: $uri}) {
    ...ExternalLink
  }
}
    ${ExternalLinkFragmentDoc}`;
export type IUpdateExternalLinkMutationFn = Apollo.MutationFunction<IUpdateExternalLinkMutation, IUpdateExternalLinkMutationVariables>;

/**
 * __useUpdateExternalLinkMutation__
 *
 * To run a mutation, you first call `useUpdateExternalLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalLinkMutation, { data, loading, error }] = useUpdateExternalLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      uri: // value for 'uri'
 *   },
 * });
 */
export function useUpdateExternalLinkMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateExternalLinkMutation, IUpdateExternalLinkMutationVariables>) {
        return Apollo.useMutation<IUpdateExternalLinkMutation, IUpdateExternalLinkMutationVariables>(UpdateExternalLinkDocument, baseOptions);
      }
export type UpdateExternalLinkMutationHookResult = ReturnType<typeof useUpdateExternalLinkMutation>;
export type UpdateExternalLinkMutationResult = Apollo.MutationResult<IUpdateExternalLinkMutation>;
export type UpdateExternalLinkMutationOptions = Apollo.BaseMutationOptions<IUpdateExternalLinkMutation, IUpdateExternalLinkMutationVariables>;