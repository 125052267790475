import { NotFoundLayout } from "@gannettdigital/community-hub-components";
import { Typography } from "@mui/material";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { Button } from "./ErrorPage.styled";

interface IProps {
  statusCode?: number;
}

const ErrorPage: React.FC<IProps> = ({ statusCode = 404 }) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" key="errorHead" />
      </Head>
      <NotFoundLayout title="We're sorry, we can't find that page.">
        <>
          {statusCode !== 404 && (
            <Typography>Error code: {statusCode}</Typography>
          )}
          <Button onClick={() => router.push("/")}>Return to Obituaries</Button>
        </>
      </NotFoundLayout>
    </>
  );
};

export default ErrorPage;
