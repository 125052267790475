// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type IExternalLinkFragment = (
  { __typename?: 'external_link' }
  & Pick<Types.IExternal_Link, 'id' | 'type' | 'uri'>
);

export const ExternalLinkFragmentDoc = gql`
    fragment ExternalLink on external_link {
  id
  type
  uri
}
    `;