// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { ILifeStoryFragment } from '../../fragments/__generated__/lifeStory.types';
import { ILifeStoryLocationFragment } from '../../fragments/__generated__/lifeStoryLocation.types';
import { gql } from '@apollo/client';
import { LifeStoryFragmentDoc } from '../../fragments/__generated__/lifeStory.types';
import { LifeStoryLocationFragmentDoc } from '../../fragments/__generated__/lifeStoryLocation.types';
import * as Apollo from '@apollo/client';
export type IUpdateObituaryMutationVariables = Types.Exact<{
  life_story_id: Types.Scalars['uuid'];
  first_name?: Types.Maybe<Types.Scalars['String']>;
  middle_name?: Types.Maybe<Types.Scalars['String']>;
  last_name?: Types.Maybe<Types.Scalars['String']>;
  suffix?: Types.Maybe<Types.Scalars['String']>;
  birth?: Types.Maybe<Types.Scalars['date']>;
  passing?: Types.Maybe<Types.Scalars['date']>;
  story?: Types.Maybe<Types.Scalars['String']>;
  life_story_location_id: Types.Scalars['uuid'];
  city?: Types.Maybe<Types.Scalars['String']>;
  state?: Types.Maybe<Types.Scalars['String']>;
  country?: Types.Maybe<Types.Scalars['String']>;
}>;


export type IUpdateObituaryMutation = (
  { __typename?: 'mutation_root' }
  & { update_life_story_by_pk?: Types.Maybe<(
    { __typename?: 'life_story' }
    & ILifeStoryFragment
  )>, update_life_story_location_by_pk?: Types.Maybe<(
    { __typename?: 'life_story_location' }
    & ILifeStoryLocationFragment
  )> }
);


export const UpdateObituaryDocument = gql`
    mutation UpdateObituary($life_story_id: uuid!, $first_name: String, $middle_name: String, $last_name: String, $suffix: String, $birth: date, $passing: date, $story: String, $life_story_location_id: uuid!, $city: String, $state: String, $country: String) {
  update_life_story_by_pk(
    pk_columns: {id: $life_story_id}
    _set: {first_name: $first_name, middle_name: $middle_name, last_name: $last_name, suffix: $suffix, birth: $birth, passing: $passing, story: $story}
  ) {
    ...LifeStory
  }
  update_life_story_location_by_pk(
    pk_columns: {id: $life_story_location_id}
    _set: {city: $city, state: $state, country: $country}
  ) {
    ...LifeStoryLocation
  }
}
    ${LifeStoryFragmentDoc}
${LifeStoryLocationFragmentDoc}`;
export type IUpdateObituaryMutationFn = Apollo.MutationFunction<IUpdateObituaryMutation, IUpdateObituaryMutationVariables>;

/**
 * __useUpdateObituaryMutation__
 *
 * To run a mutation, you first call `useUpdateObituaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObituaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObituaryMutation, { data, loading, error }] = useUpdateObituaryMutation({
 *   variables: {
 *      life_story_id: // value for 'life_story_id'
 *      first_name: // value for 'first_name'
 *      middle_name: // value for 'middle_name'
 *      last_name: // value for 'last_name'
 *      suffix: // value for 'suffix'
 *      birth: // value for 'birth'
 *      passing: // value for 'passing'
 *      story: // value for 'story'
 *      life_story_location_id: // value for 'life_story_location_id'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useUpdateObituaryMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateObituaryMutation, IUpdateObituaryMutationVariables>) {
        return Apollo.useMutation<IUpdateObituaryMutation, IUpdateObituaryMutationVariables>(UpdateObituaryDocument, baseOptions);
      }
export type UpdateObituaryMutationHookResult = ReturnType<typeof useUpdateObituaryMutation>;
export type UpdateObituaryMutationResult = Apollo.MutationResult<IUpdateObituaryMutation>;
export type UpdateObituaryMutationOptions = Apollo.BaseMutationOptions<IUpdateObituaryMutation, IUpdateObituaryMutationVariables>;