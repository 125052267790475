// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type ILifeStoryLocationFragment = (
  { __typename?: 'life_story_location' }
  & Pick<Types.ILife_Story_Location, 'id' | 'city' | 'state' | 'country'>
);

export const LifeStoryLocationFragmentDoc = gql`
    fragment LifeStoryLocation on life_story_location {
  id
  city
  state
  country
}
    `;