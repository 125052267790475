import { ContentWell } from "@/components/molecules";
import { ObituaryDrawer } from "@/components/organisms";
import { IUserCreatedObituaryFragment } from "@/graphql/fragments/__generated__/userCreatedObituary.types";
import { useDeleteExternalLinkMutation } from "@/graphql/mutations/__generated__/deleteExternalLink.types";
import { useInsertExternalLinkMutation } from "@/graphql/mutations/__generated__/insertExternalLink.types";
import { useUpdateExternalLinkMutation } from "@/graphql/mutations/__generated__/updateExternalLink.types";
import { useUpdateObituaryMutation } from "@/graphql/mutations/__generated__/updateObituary.types";
import { useGetUserCreatedObituaryBySlugQuery } from "@/graphql/queries/__generated__/getUserCreatedObituaryBySlug.types";
import { IExternal_Link_Type_Enum } from "@/graphql/__generated__/graphql.types";
import { formatFromDBDate, formatToDBDate } from "@/helpers/dates";
import logger from "@/logging/client";
import {
  Typography,
  useSnackbar
} from "@gannettdigital/community-hub-components";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { LightBlueButton, Wrapper } from "./EditObituaryPage.styled";

interface IProps {
  slug: string;
  loading?: boolean;
}

const EditObituaryPage: React.FC<IProps> = ({
  slug,
  loading: externalLoading
}) => {
  const [open, setOpen] = useState(true);
  const { showSnackbar } = useSnackbar();

  const { data, loading, error, refetch } =
    useGetUserCreatedObituaryBySlugQuery({
      variables: { slug },
      onCompleted: data => {
        if (!data || !data.obituary || data.obituary.length == 0) {
          setOpen(false);
        }
      }
    });

  const [updateObituary] = useUpdateObituaryMutation();
  const [insertExternalLink] = useInsertExternalLinkMutation();
  const [updateExternalLink] = useUpdateExternalLinkMutation();
  const [deleteExternalLink] = useDeleteExternalLinkMutation();

  const obituary = data && data.obituary[0];
  if (obituary) {
    obituary.life_stories[0].birth = formatFromDBDate(
      obituary.life_stories[0].birth
    );

    obituary.life_stories[0].passing = formatFromDBDate(
      obituary.life_stories[0].passing
    );
  }

  const handleSubmit = async (formValues: IUserCreatedObituaryFragment) => {
    try {
      const updateObituaryResponse = await updateObituary({
        variables: {
          life_story_id: formValues.life_stories[0].id,
          first_name: formValues.life_stories[0].first_name,
          middle_name: formValues.life_stories[0].middle_name,
          last_name: formValues.life_stories[0].last_name,
          suffix: formValues.life_stories[0].suffix,
          birth: formatToDBDate(formValues.life_stories[0].birth),
          passing: formatToDBDate(formValues.life_stories[0].passing),
          story: formValues.life_stories[0].story,
          life_story_location_id: formValues.life_story_locations[0].id,
          city: formValues.life_story_locations[0].city,
          state: formValues.life_story_locations[0].state,
          country: formValues.life_story_locations[0].country
        }
      });

      if (updateObituaryResponse.errors) {
        throw updateObituaryResponse.errors[0];
      }

      if (formValues.external_links[0].id) {
        if (formValues.external_links[0].uri) {
          const updateExternalLinkResponse = await updateExternalLink({
            variables: {
              id: formValues.external_links[0].id,
              uri: formValues.external_links[0].uri
            }
          });

          if (updateExternalLinkResponse.errors) {
            throw updateExternalLinkResponse.errors[0];
          }
        } else {
          const deleteExternalLinkResponse = await deleteExternalLink({
            variables: {
              id: formValues.external_links[0].id
            }
          });

          if (deleteExternalLinkResponse.errors) {
            throw deleteExternalLinkResponse.errors[0];
          }
        }
      } else if (formValues.external_links[0].uri) {
        const insertExternalLinkResponse = await insertExternalLink({
          variables: {
            obituary_id: formValues.id,
            type: IExternal_Link_Type_Enum.Gofundme,
            uri: formValues.external_links[0].uri
          }
        });

        if (insertExternalLinkResponse.errors) {
          throw insertExternalLinkResponse.errors[0];
        }
      }

      showSnackbar({
        title: "Thank you!",
        message: "The obituary has been saved. We will contact you soon!"
      });

      setOpen(false);
    } catch (error: any) {
      logger.error(`Could not create obituary: ${error.message}`, error);

      showSnackbar({
        title: "An error has occurred!",
        message: "You might not have permissions for this operation!",
        autoHideDuration: 3000
      });
    }

    refetch();
  };

  return (
    <>
      <ContentWell>
        <Box px={{ xs: 3, sm: 7.5 }} py={{ xs: 7.5 }} pb={{ xs: 0 }}>
          <Typography variant="h1" textAlign="center">
            This page is used only to test the edit obituary workflow
          </Typography>
          <br />
          <Wrapper>
            <LightBlueButton onClick={() => setOpen(true)}>
              Open drawer
            </LightBlueButton>
          </Wrapper>
          <br />
          <Typography variant="h2">stringified obit: </Typography>
          {loading && <h2>loading...</h2>}
          {!loading && (
            <div>
              <pre>
                {obituary
                  ? JSON.stringify(obituary, null, 2)
                  : "Obituary not found"}
              </pre>
            </div>
          )}
          {!loading && error && <div>error {error.message}</div>}
        </Box>
      </ContentWell>

      <ObituaryDrawer
        loadingData={externalLoading || loading}
        loadingSubmit={false}
        obituary={obituary}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default EditObituaryPage;
