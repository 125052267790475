// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type IFromSalesOrderFragment = (
  { __typename?: 'from_sales_order' }
  & Pick<Types.IFrom_Sales_Order, 'id' | 'obituary_id' | 'contact_firstname' | 'contact_lastname' | 'billing_address' | 'billing_city' | 'billing_state' | 'billing_zip' | 'billing_phone' | 'billing_email' | 'verification_provider' | 'verification_contact' | 'verification_phone' | 'have_accepted_terms_and_service' | 'print_start' | 'print_end'>
);

export const FromSalesOrderFragmentDoc = gql`
    fragment FromSalesOrder on from_sales_order {
  id
  obituary_id
  contact_firstname
  contact_lastname
  billing_address
  billing_city
  billing_state
  billing_zip
  billing_phone
  billing_email
  verification_provider
  verification_contact
  verification_phone
  have_accepted_terms_and_service
  print_start
  print_end
}
    `;