// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IExternalLinkFragment } from '../../fragments/__generated__/externalLink.types';
import { gql } from '@apollo/client';
import { ExternalLinkFragmentDoc } from '../../fragments/__generated__/externalLink.types';
import * as Apollo from '@apollo/client';
export type IDeleteExternalLinkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type IDeleteExternalLinkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_external_link_by_pk?: Types.Maybe<(
    { __typename?: 'external_link' }
    & IExternalLinkFragment
  )> }
);


export const DeleteExternalLinkDocument = gql`
    mutation DeleteExternalLink($id: uuid!) {
  delete_external_link_by_pk(id: $id) {
    ...ExternalLink
  }
}
    ${ExternalLinkFragmentDoc}`;
export type IDeleteExternalLinkMutationFn = Apollo.MutationFunction<IDeleteExternalLinkMutation, IDeleteExternalLinkMutationVariables>;

/**
 * __useDeleteExternalLinkMutation__
 *
 * To run a mutation, you first call `useDeleteExternalLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExternalLinkMutation, { data, loading, error }] = useDeleteExternalLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExternalLinkMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteExternalLinkMutation, IDeleteExternalLinkMutationVariables>) {
        return Apollo.useMutation<IDeleteExternalLinkMutation, IDeleteExternalLinkMutationVariables>(DeleteExternalLinkDocument, baseOptions);
      }
export type DeleteExternalLinkMutationHookResult = ReturnType<typeof useDeleteExternalLinkMutation>;
export type DeleteExternalLinkMutationResult = Apollo.MutationResult<IDeleteExternalLinkMutation>;
export type DeleteExternalLinkMutationOptions = Apollo.BaseMutationOptions<IDeleteExternalLinkMutation, IDeleteExternalLinkMutationVariables>;