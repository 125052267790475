// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type IImageFragment = (
  { __typename?: 'image' }
  & Pick<Types.IImage, 'id' | 'uri' | 'type' | 'params' | 'caption'>
);

export const ImageFragmentDoc = gql`
    fragment Image on image {
  id
  uri
  type
  params
  caption
}
    `;