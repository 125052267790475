// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type ITagFragment = (
  { __typename?: 'tag' }
  & Pick<Types.ITag, 'id' | 'value'>
);

export const TagFragmentDoc = gql`
    fragment Tag on tag {
  id
  value
}
    `;