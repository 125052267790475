import styled from "@/components/contexts/ThemeProvider/styled";
import { Button } from "@gannettdigital/community-hub-components";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

export const LightBlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.all.blue5,
  borderColor: theme.palette.all.blue5,
  borderRadius: "4px"
}));
