// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { ILifeStoryFragment } from './lifeStory.types';
import { ILifeStoryLocationFragment } from './lifeStoryLocation.types';
import { IExternalLinkFragment } from './externalLink.types';
import { IImageFragment } from './image.types';
import { ITagFragment } from './tag.types';
import { IFromSalesOrderFragment } from './fromSalesOrder.types';
import { gql } from '@apollo/client';
import { LifeStoryFragmentDoc } from './lifeStory.types';
import { LifeStoryLocationFragmentDoc } from './lifeStoryLocation.types';
import { ExternalLinkFragmentDoc } from './externalLink.types';
import { ImageFragmentDoc } from './image.types';
import { TagFragmentDoc } from './tag.types';
import { FromSalesOrderFragmentDoc } from './fromSalesOrder.types';
export type IUserCreatedObituaryFragment = (
  { __typename?: 'obituary' }
  & Pick<Types.IObituary, 'id' | 'slug'>
  & { life_stories: Array<(
    { __typename?: 'life_story' }
    & ILifeStoryFragment
  )>, life_story_locations: Array<(
    { __typename?: 'life_story_location' }
    & ILifeStoryLocationFragment
  )>, external_links: Array<(
    { __typename?: 'external_link' }
    & IExternalLinkFragment
  )>, images: Array<(
    { __typename?: 'image' }
    & IImageFragment
  )>, obituary_tags: Array<(
    { __typename?: 'obituary_tag' }
    & { tag: (
      { __typename?: 'tag' }
      & ITagFragment
    ) }
  )>, from_sales_orders: Array<(
    { __typename?: 'from_sales_order' }
    & IFromSalesOrderFragment
  )> }
);

export const UserCreatedObituaryFragmentDoc = gql`
    fragment UserCreatedObituary on obituary {
  id
  slug
  life_stories(order_by: {created_at: desc}, limit: 1) {
    ...LifeStory
  }
  life_story_locations(order_by: {created_at: desc}, limit: 1) {
    ...LifeStoryLocation
  }
  external_links(order_by: {created_at: desc}, limit: 1) {
    ...ExternalLink
  }
  images {
    ...Image
  }
  obituary_tags {
    tag {
      ...Tag
    }
  }
  from_sales_orders {
    ...FromSalesOrder
  }
}
    ${LifeStoryFragmentDoc}
${LifeStoryLocationFragmentDoc}
${ExternalLinkFragmentDoc}
${ImageFragmentDoc}
${TagFragmentDoc}
${FromSalesOrderFragmentDoc}`;