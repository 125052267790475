// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IUserCreatedObituaryFragment } from '../../fragments/__generated__/userCreatedObituary.types';
import { gql } from '@apollo/client';
import { UserCreatedObituaryFragmentDoc } from '../../fragments/__generated__/userCreatedObituary.types';
import * as Apollo from '@apollo/client';
export type IGetUserCreatedObituaryBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type IGetUserCreatedObituaryBySlugQuery = (
  { __typename?: 'query_root' }
  & { obituary: Array<(
    { __typename?: 'obituary' }
    & IUserCreatedObituaryFragment
  )> }
);


export const GetUserCreatedObituaryBySlugDocument = gql`
    query getUserCreatedObituaryBySlug($slug: String!) {
  obituary(where: {slug: {_eq: $slug}}) {
    ...UserCreatedObituary
  }
}
    ${UserCreatedObituaryFragmentDoc}`;

/**
 * __useGetUserCreatedObituaryBySlugQuery__
 *
 * To run a query within a React component, call `useGetUserCreatedObituaryBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCreatedObituaryBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCreatedObituaryBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetUserCreatedObituaryBySlugQuery(baseOptions: Apollo.QueryHookOptions<IGetUserCreatedObituaryBySlugQuery, IGetUserCreatedObituaryBySlugQueryVariables>) {
        return Apollo.useQuery<IGetUserCreatedObituaryBySlugQuery, IGetUserCreatedObituaryBySlugQueryVariables>(GetUserCreatedObituaryBySlugDocument, baseOptions);
      }
export function useGetUserCreatedObituaryBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserCreatedObituaryBySlugQuery, IGetUserCreatedObituaryBySlugQueryVariables>) {
          return Apollo.useLazyQuery<IGetUserCreatedObituaryBySlugQuery, IGetUserCreatedObituaryBySlugQueryVariables>(GetUserCreatedObituaryBySlugDocument, baseOptions);
        }
export type GetUserCreatedObituaryBySlugQueryHookResult = ReturnType<typeof useGetUserCreatedObituaryBySlugQuery>;
export type GetUserCreatedObituaryBySlugLazyQueryHookResult = ReturnType<typeof useGetUserCreatedObituaryBySlugLazyQuery>;
export type GetUserCreatedObituaryBySlugQueryResult = Apollo.QueryResult<IGetUserCreatedObituaryBySlugQuery, IGetUserCreatedObituaryBySlugQueryVariables>;